body { background: #F7F7F7; }
.panel-footer, .well, .capacity_percent { background: #F4F4F4; }
html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }
#main { padding-top: 60px; }
.radio label, .checkbox label { display: block; }
.alert>.h3 { margin-top: 0; }
col.thumbnail > img, .thumbnail a > img, .responsive { width: 100%; height: auto !important; }
.select-label { padding-top: 0.5em; }
div.panel > div.form-group:first-child { padding-top: 15px; }
body #main ol.breadcrumb > li + li:before { content: ">" !important; }
ol.breadcrumb { margin-bottom: 0; }
.capacity, .capacity_percent { height: 40px; }
.shift_level .capacity, .shift_level .capacity_percent { height: 10px; }

.capacity {  border: 1px solid #DDD; position: relative; margin: 0 auto; }
.capacity_percent { position: absolute; top: -1px; right: -1px; border: 1px solid #DDD; }
#previews_container .file-row .thumbnail { padding-top: 8px; height: 220px; width: auto !important; }
#previews_container .file-row .thumbnail img { max-height: 120px; box-shadow: 1px 1px 1px 2px #EFEFEF; }
#previews_container .file-row { padding-bottom: 20px; text-align: center; position: relative; }
#previews_container .file-row .caption { position: absolute; bottom: 40px; left: 25%; }
ul.list-group li.list-group-item a.btn { width: 100%; text-align: left; }
a.btn .glyphicon { opacity: 0.8; margin-right: 4px; }

.panel-heading a { width: 100%; display: block; }
.panel-body select { width: 100%; }

.panel-heading #report_export a { width: auto!important; display: inline-block!important; }

.toggle-radios input[type="radio"] { position: absolute; clip: rect(0, 0, 0, 0); pointer-events: none; }

.help-block { font-size: 0.9em; }

.table thead th { font-weight: normal; background: #777777; color: #F4F4F4; }
.table thead th label { padding: 5px 2px 0 2px; font-weight: normal; }
.table td, .table th { vertical-align: middle !important; }
.table td .table, .table td .table td .form-group { margin-bottom: 0; }
.table.table-borderless td, .table.table-borderless th, .table td .table td { border-top: none; }

th>a { text-decoration: none; color: #FFF; }
th>a:hover, th>a:focus { color: #FCFCFC; }

.page-header { padding-bottom: 10px; margin: 15px 15px 0 15px; }
.page-header h3 { font-weight: normal; color: #777777; margin: 0; }

.breadcrumb_wrapper { border-bottom: 1px solid #eeeeee; margin: 5px 0 15px 0; }
ol.breadcrumb { background-color: #FCFCFC; }

.delete_button { margin-right: 30px; }
.note-editable { min-height: 310px; }
.btn-toolbar { margin-left: 0; padding: 5px 0; background: #F4F4F4; border-bottom: 1px solid #CCC; }
.note-editor { border-color: #CCC; }

.chart_wrapper ul.legend { padding-left: 0; margin-left: -30px; }
.chart_wrapper ul.legend li { list-style: none; }
.chart_wrapper.with_key ul.legend li span { width: 15px; height: 15px; display: inline-block; margin: 5px 15px 0 0; position: relative; top: 2px; }

.chart-wrapper { position: relative; }

.chart_wrapper, .chart_wrapper .chart { float: left; }
.chart_wrapper.with_key, .chart_wrapper { width: 100% !important; }
.legend_wrapper { float: right; }

.row.bordered { border-top: 1px solid #DDD; padding-top: 15px; }
.page-header + .row.bordered { border-top: none; }

.annotations .list-group-item { min-height: 60px; position: relative; padding-left: 60px; vertical-align: middle; }
.annotations .badge { position: absolute; left: 15px; margin: 0; font-size: 30px; }

.nav-tabs { background: #EFEFEF; }
.nav-tabs > li.active { margin-top: -5px; }
.nav-tabs > li > a { margin-right: -1px; border: 1px solid #DDD; background: #F4F4F4; }
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus { padding-bottom: 15px; background: #FFF;}

td .list-group { margin-bottom: 0; border-bottom: none; }
td .list-group .list-group-item { border-radius: 0; border: none; border-top: 1px solid #DDD; }
td .list-group .list-group-item:first-child { border-top: none; border-bottom: 1px solid #DDD; }

.table > tbody > tr.active > td .table > tbody > tr > td { background-color: #F5F5F5; }
.list-group-item.bg-success { background: #dff0d8 !important; }

.navbar-nav > li > strong {
	color: #EFEFEF;
	display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
}

@media (min-width: 768px) {
	.control-label.col-sm-3 { margin-top: 6px; }
	.navbar-nav > li > strong {
	    padding-top: 15px;
	    padding-bottom: 15px;
	}
}

.shift_level { padding: 5px; }
.shift_level .panel-body { padding: 0;  }
.shift_level .panel-body .list-group-item { border-radius: 0; border: none; border-top: 1px solid #DDD; }
.shift_level .panel-body .list-group-item:first-child { border-top: none; }

.time_wrapper { line-height: 28px; padding: 0 10px; border: 1px solid #DDD; }
.time_wrapper div { padding: 0; }
.time_wrapper .glyphicon { color: #CCC; top: 3px; left: -6px; }

.process-box .panel-default { color: #C4C4C4; }
.dropdown-menu li span.badge { float: right; }

.level_wrapper { text-align: center; background: #EFEFEF; border: 1px solid #E4E4E4; border-radius: 4px; }
.level_chart { width: 12px; padding: 1px; margin: 4px auto; }
.level_wrapper .level { margin: 0 1px; width: 10px; height: 10px; border: 1px solid #3C3C3C; margin-bottom: 4px; background: #C4C4C4; }
.level_wrapper .level:last-child { margin-bottom: 0; }
.level_wrapper .level.is { background: #5CB85C; }

th small { font-weight: normal; }

.settings #settings_form label { font-size: 100%; color: #3C3C3C; }
label + .radio-inline { margin-left: 20px; }
.accordion-heading { padding: 0; }
.accordion-heading a { padding: 12px; }
a.bg-primary { color: #FFF; }

.sweet-alert ul { text-align: left; }

ul.dd-options { height: 366px; }
.dd-option-image, .dd-selected-image { max-width: 223px !important; width: 223px !important; opacity: 0.6; }
.dd-option { padding: 0 !important; background: #FBFBFB !important; }
.dd-select { background: #F8F8F8 !important; }
.dd-option-selected, .dd-option:hover, .dd-option:focus { background: #FFF !important; }
.dd-option-selected .dd-selected-image, .dd-option:hover .dd-option-image, .dd-option:focus .dd-option-image { opacity: 1; }

.dd-option label.dd-option-text { line-height: 1em !important; }

form .row .row label.control-label { padding-top: 0.5em; }

#invoice_table tbody td:last-child, #invoice_table tfoot td { text-align: right; }
#invoice_table tfoot tr:last-child td { background: #DEDEDE; font-weight: bold; }

.annotations_wrapper { margin: 0 auto; position: relative; width: 500px; }

.file_icon_wrapper { text-align: center; float: left; margin-right: 15px; }
.file_icon_wrapper .label { font-size: 18px; text-align: center; position: relative; top: -75px; }
.file_icon { font-size: 96px; }

.file_icon_wrapper.small-icon { text-align: center; float: left; margin-right: 10px; }
.file_icon_wrapper.small-icon .label { font-size: 12px; text-align: center; position: relative; top: -40px; }
.small-icon .file_icon { font-size: 48px; }
.ui-helper-hidden-accessible { display: none; }

.badge.redacted { border-radius: 0 !important; text-transform: uppercase; font-weight: normal; }

.dbox { display: none; }
.thumbnail.deleted { opacity: 0.6; border-color: red; }
.thumbnail .extension { position: absolute; top: 20px; left: 42%; padding: 2px 8px; font-size: 15px; color: #FFF; background: #3b3b3b; text-transform: uppercase; }

.clickable { cursor: pointer; }
.clickable:hover, .clickable:focus { opacity: 0.8; }
tr.clickable:hover, tr.clickable:focus { background: #9C9C9C !important; }

.colour_pot .badge-colour-box { box-sizing: border-box; width: 50px; height: 50px; display: inline-block; border: 2px solid #E3E3E3; }
.colour_wrapper .colour_pot .badge-colour-box { opacity: 0.8; }
.colour_wrapper .colour_pot.active .badge-colour-box { border: 2px solid #000; border-radius: 25px; }
.colour_wrapper .colour_pot.active .badge-colour-box, .colour_wrapper .colour_pot:hover .badge-colour-box, .colour_wrapper .colour_pot:focus .badge-colour-box { opacity: 1; }

ul#layer_colours { margin: 0; padding: 0; list-style: none; }
ul#layer_colours li { margin: 0 0 4px 0; padding: 0; list-style: none; }
ul#layer_colours li span { margin-left: 8px; line-height: 30px; vertical-align: top; display: inline-block; }

#stripeform { padding: 15px; margin-bottom: 20px; background-color: #fff; border: 1px solid #bce8f1; border-radius: 4px; box-shadow: 0 1px 1px rgba(0, 0, 0, 0.0); }
#stripeform #cclogo { min-height: 60px; background: 10px 10px no-repeat transparent url(/images/ccards.png); }
.navbar-inverse { background-color: #52555A; }
#ccemails, #text_order_list { list-style: none; padding: 0; }

.g-recaptcha { margin-left: 25%; }

.modal-body hr:last-child { display: none; }
.modal-body { font-size: 14px !important; }

.badge_type_selector .panel-header, .badge_type_selector .panel-body { text-align: center; }
.badge_type_selector .panel-body input { display: none; }
.badge_type_selector .panel-body img { max-width: 100% !important; width: auto !important; max-height: 200px !important; }
.badge_type_selector.panel-default .panel-body img { opacity: 0.8em; filter: gray; -webkit-filter: grayscale(1); filter: grayscale(1);  }
.badge_type_selector.panel-success .panel-body img, .badge_type_selector:hover .panel-body img, .badge_type_selector:focus .panel-body img { opacity: 1; -webkit-filter: grayscale(0); filter: none; }

h3.panel-title .btn.btn-info.pull-right.btn-sm { margin-top: -5px; }